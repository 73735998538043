<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center
        .col-lg-8
          h1.text-center {{ this.title}}
          p.text-center 記録する内容を入力してください
          .alert.alert-danger(v-if="message") {{ message }}
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label 岩場
              select#rockyName.form-control(name="rockyName" v-model="rockyId" v-on:change="changeRocky" ref="selRocky" required)
                option(hidden disabled) 選択してください
                option(v-for="item in rockyData" v-bind:key="item.Id" :value="item.rockyId") {{item.rockyName}}
              .invalid-feedback 岩場を選択してください
            .text-center
              b-spinner.mb-4(variant="primary" label="Loading..." v-if="isLoadingRocky")
            .form-group
              label 岩
              select#rockName.form-control(name="rockName" v-model="rockId" v-on:change="changeRock" ref="selRock" :disabled="isLoadingRock" required)
                option(hidden disabled) 選択してください
                option(v-for="rockItem in rockData" v-bind:key="rockItem.id" :value="rockItem.id") {{rockItem.name ? rockItem.name : '(岩名なし)'}}
              .invalid-feedback 岩を選択してください
            .text-center
              b-spinner.mb-4(variant="primary" label="Loading..." v-if="isLoadingRock")
            .form-group
              label ルート選択
              select#routeName.form-control(v-model="routeId" ref="selRoute" :disabled="isLoadingRoute" required)
                option(hidden disabled) 選択してください
                option(v-for="routeItem in routeList" :value="routeItem.routeId" selected) 
                  span(v-html="(routeItem.routeName ? routeItem.routeName : '(ルート名なし)') + '　' + routeItem.gradeName")
                //- option(v-for="routeItem in routeList" :value="routeItem.routeId" selected) {{ (routeItem.routeName ? routeItem.routeName : '(ルート名なし)') + '　' + routeItem.gradeName }} 
              .invalid-feedback ルートを選択してください
            .text-center
              b-spinner.mb-4(variant="primary" label="Loading..." v-if="isLoadingRoute")
            .form-group
              label 登攀区分
              br
              .form-check.pl-0
                input#climbKind4.form-check-input(type="radio" v-model="climbKind" name="climbKind" value="4" required @change="changeClimbKind3")
                label.form-check-label.radio-label(for="climbKind4") チャレンジ中
              .form-check.pl-0
                input#climbKind3.form-check-input(type="radio" v-model="climbKind" name="climbKind" value="3" required)
                label.form-check-label.radio-label(for="climbKind3") 完登
                div.ml-3.mt-1.small オンサイトかフラッシュの場合は選択してください
                .custom-control.custom-checkbox.ml-3.mt-1
                  input#climbKind1.custom-control-input(type="checkbox" v-model="completeClimbKind1" name="climbKind" :disabled="climbKind!='3'" @change="changeClimbKind1")
                  label.custom-control-label(for="climbKind1") オンサイト(一撃)
                .custom-control.custom-checkbox.ml-3
                  input#climbKind2.custom-control-input(type="checkbox" v-model="completeClimbKind2" name="climbKind" :disabled="climbKind!='3'" @change="changeClimbKind2")
                  label.custom-control-label(for="climbKind2") フラッシュ
            #genderError.invalid-feedback 区分を選択してください
            .form-group
              label(for="climbDate") 日付
              Datepicker(bootstrap-styling :format="DatePickerFormat" use-utc :language="ja" type="text" placeholder="日付" @focusin.native="calendarOpened" v-model="climbDate" @selected="changeDate")
              .invalid-feedback(ref="calender") 日付を入力してください
            input.btn.btn-primary.mb-3.col(type="submit" ref="buttonName" v-bind:disabled="isDisabled")
          //-記録作成モーダル
          b-modal#record-modal(@hidden="hideModal" hide-footer hide-header)
            .text-center.mb-5.mt-3.record 記録を作成しました
            .text-center.mb-3.record-success
              div.mb-2 獲得
              h3.mb-5.point1.badge.badge-pill.badge-info {{ addPoint }}ポイント
              div.mb-2 次のレベルまであと
              div.mb-4.point2.badge.badge-pill.badge-light あと{{ nextLevelPoint - ponit }}ポイント
            div.progress.text-center(style="height: 30px;")
              div.progress-bar.bg-info(role="progressbar" :style="{width: this.progressbarStyle + '%'}" aria-valuenow=this.point aria-valuemin=this.prevLevelPoint aria-valuemax=this.nextLevelPoint) {{ ponit }}ポイント
            span.text-lest {{ prevLevelPoint }}
            .float-right.mb-5 {{  nextLevelPoint }}
            input.btn.btn-primary.mb-2.col(type="submit" @click="hideModal" value="OK")
          //-レベルアップモダール
          b-modal#record-levelUp-modal(@hidden="hideLevelUpModal" hide-footer hide-header)
            .text-center
              h1.levelUp レベルアップ！！
              img(:src="materialURL + iconPath")
              .levelUp-red おめでとうございます！
              .levelUp-red.mb-5 新しいバッジをゲットしました。
              input.btn.btn-primary.mb-3.col(type="submit" @click="hideLevelUpModal" value="OK") 
</template>

<script>
import axios from 'axios'
import Datepicker from 'vuejs-datepicker'
import {ja} from 'vuejs-datepicker/dist/locale'

export default {
  //データオブジェクト
  data() {
    return {
      message: '',
      climbDate: '',
      isDisabled: false,
      materialURL: process.env.VUE_APP_MATERIAL_URL,
      rockyData: null,
      rockyId: this.$route.query.rockyId,
      rockName: '',
      rockData: null,
      routeList: null,
      routeId: '',
      rockId: this.$route.query.rockId,
      id: this.$route.query.id,
      title: '',
      isId: true,
      ja: ja,
      DatePickerFormat: 'yyyy/MM/dd',
      isLoadingRock: false,
      isLoadingRocky: false,
      recordKind: this.$route.query.ref,
      completeClimbKind1: '',
      completeClimbKind2: '',
      climbKind: '',
      addPoint: '',
      prevLevelPoint: '',
      nextLevelPoint: '',
      ponit: '',
      isLevelUp: '',
      iconPath: '',
      progressbarStyle: '',
      progressbarPoint1: '',
      progressbarPoint2: '',
      isLoadingRoute: false,
    }
  },
  methods: {
    //オンサイト選択時
    changeClimbKind1() {
      this.completeClimbKind2 = false
    },
    //フラッシュ選択時
    changeClimbKind2() {
      this.completeClimbKind1 = false
    },
    //チャレンジ中選択時
    changeClimbKind3() {
      this.completeClimbKind1 = false
      this.completeClimbKind2 = false
    },

    asyncDialog(msg) {
      return new Promise((resolve) => {
        this.$bvModal.msgBoxOk(msg, {
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'success',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
        .then(value => {
          resolve(value)
        })
        .catch(err => {
          // An error occurred
        })
      })
    },

    //岩データ取得
    changeRocky() {
      this.isLoadingRock = true
      this.isDisabled = true

      this.routeList = []

      const jsonUrl = process.env.VUE_APP_API_GATEWAY + '/api-rock'
      axios.get(jsonUrl, {
        params: {
          "rockyId": this.rockyId,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.rockData = res.data.rockList
        this.prefecturesId = res.data.prefecturesId
        this.isLoadingRock = false
        this.isDisabled = false
      })
    },

    //岩時選択のルート取得
    changeRock() {
      this.isDisabled = true
      this.isLoadingRoute = true
      
      const jsonUrl2 = process.env.VUE_APP_API_GATEWAY + '/api-route'
      axios.get(jsonUrl2, {
        params: {
          "rockId": this.rockId,
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res=> {
        this.routeList = res.data
        this.isDisabled = false
        this.isLoadingRoute = false
      })
    },

    //カレンダー入力時
    changeDate() {
      this.$refs.calender.classList.remove('d-block')
    },

    //カレンダー初期値
    calendarOpened() {
      if(this.climbDate == '') {
        //日付取得
        this.climbDate = new Date () 
      }
    },

    //レベルアップモーダルＯＫボタン押した時
    hideLevelUpModal() {
      this.$bvModal.hide('record-levelUp-modal')
      this.routerPush()
    },

    //記録作成モーダルＯＫボタン押した時
    hideModal() {
      //レベルアップした場合
      if(this.isLevelUp) {
        this.$bvModal.hide('record-modal')
        this.$bvModal.show('record-levelUp-modal')
      }else {
        this.routerPush()
      }
    },

    routerPush() {
      if(this.recordKind == 'route_detail') {
        this.$router.push({ name: 'myPage_:userId_routeDetail', params: { userId: this.$user.userId }, query: { rockyId: this.rockyId, routeId: this.routeId} })
      }else if(this.recordKind == 'rockyInfo') {
        this.$router.push({ name: 'rockyInfo_:rockyId' , params: {rockyId: this.rockyId}})
      }else if(this.recordKind == 'rockId') {
        this.$router.push({ name: 'rockyInfo_:rockyId_:rockId' , params: { rockyId: this.rockyId, rockId: this.rockId}})
      }else if(this.recordKind == 'routeId') {
        this.$router.push({ name: 'rockyInfo_:rockyId_:rockId_:routeId' , params: { rockyId: this.rockyId, rockId: this.rockId, routeId: this.routeId}})
      }else {
        this.$router.push('/record/list')
      }
    },

    //登攀記録処理
    async exec(e) {
      this.isDisabled = true

      this.message = ''

      //エラー処理
      if(e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation()
      }

      //カレンダー入力チェック
      if(this.climbDate == '') {
        this.$refs.calender.classList.add('d-block')
      }

      if(e.target.checkValidity() === false || this.climbDate == '') {
        this.isDisabled = false
        return
      }

      let climbKind = this.climbKind
      
      //オンサイト、フラッシュ選択した場合
      if(this.completeClimbKind1) {
        climbKind = '1'
      }else if(this.completeClimbKind2) {
        climbKind = '2'
      }
      
      //セレクトタグのref取得
      let sel = this.$refs.selRocky
      let rockyName = sel.options[sel.selectedIndex].text

      let sel2 = this.$refs.selRock
      let rockName = sel2.options[sel2.selectedIndex].text

      let sel3 = this.$refs.selRoute
      let routeName = sel3.options[sel3.selectedIndex].text

      const url = process.env.VUE_APP_API_GATEWAY + '/api-record'
      const params = {
        rockyId: parseInt(this.rockyId),
        rockId: parseInt(this.rockId),
        routeId: parseInt(this.routeId),
        climbKind: climbKind,
        climbDate: this.climbDate,
        prefecturesId: parseInt(this.prefecturesId),
        rockyName: rockyName,
        rockName: rockName,
        routeName: routeName,
      }

      //編集の場合
      if (this.isId) {
        params.id = this.id

        //内容送信
        axios.patch(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
          .then(async res => {
            this.iconPath = res.data.iconPath
            //レベルアップした場合
            if(res.data.isLevelUp == true) {  
              this.$bvModal.show('record-levelUp-modal')
            //レベルアップしなかった場合
            }else {
              await this.asyncDialog('登攀記録を更新しました')
              this.routerPush()
            }
          })
          .catch(err => {
            // エラー時の処理
            console.log(err.message)
            alert('システムエラーが発生しました')
          })
      // 記録の場合
      } else {
        // 内容送信
        axios.post(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
          .then(async res => {
            // 正常終了時の処理
            this.addPoint = res.data.addPoint
            if(this.addPoint == 0) {
              await this.asyncDialog('登攀記録を作成しました')
              this.routerPush()
              return
            }
            this.prevLevelPoint = res.data.prevLevelPoint
            this.nextLevelPoint = res.data.nextLevelPoint
            this.ponit = res.data.point
            this.nextLevelPoint = res.data.nextLevelPoint
            this.isLevelUp = res.data.isLevelUp
            this.iconPath = res.data.iconPath
            this.progressbarPoint1 = this.ponit - this.prevLevelPoint
            this.progressbarPoint2 = this.nextLevelPoint - this.prevLevelPoint
            this.progressbarStyle = (this.progressbarPoint1/this.progressbarPoint2)*100
            this.$bvModal.show('record-modal')
          })
          .catch(err => {
            // エラー時の処理
            console.log(err.message)
            alert('システムエラーが発生しました')
          })
        }
    },
  },
  //インスタンス作成後
  created() {
    this.isLoadingRocky = true

    if(!this.$user.payment) {
      // オフラインかオンラインか判断
      if(navigator.onLine) {
        this.$bvModal.show('payment-Error-modal')
      } else {
        this.$bvModal.show('network-Error-modal')
      }
      this.isLoadingRocky = false
      return
    }
    
    //岩場データ取得
    axios.get(process.env.VUE_APP_API_GATEWAY + '/api-rocky', {
      headers: {
        'Authorization' : this.$user.idToken
      }
    })
    .then(res => {
      this.rockyData = res.data
      this.isLoadingRocky = false
    })
    .catch(err => {
      console.log(err)
      this.message = 'システムエラー:表示情報を取得できませんでした'
    })
  },
  mounted() {

    if(!this.$user.payment) {
      return
    }

    if(this.id) {
      //記録編集の場合
      this.title =　'記録の編集'
      this.$refs.buttonName.value = '編集する'
      this.isId = true
      //記録の取得
      axios.get(process.env.VUE_APP_API_GATEWAY + '/api-record', {
        params: {
          "id": this.id
        },
        headers: {
          'Authorization': this.$user.idToken
        }
      })
      .then(res => {
        this.climbKind = res.data[0].climbKind
        this.climbDate = res.data[0].climbDate
        this.rockyId = res.data[0].rockyId
        this.rockId = res.data[0].rockId
        this.routeId = res.data[0].routeId
        if(this.climbKind == '1') {
          this.climbKind = '3'
          this.completeClimbKind1 = true
        }else if(this.climbKind == '2') {
          this.climbKind = '3'
          this.completeClimbKind2 = true
        }
        this.changeRocky()
        this.changeRock()        
      })
    }else if(this.rockyId) {
      //岩場情報からページ遷移してきた場合
      this.title = '記録の作成'
      this.$refs.buttonName.value = '記録する'
      this.isId = false
      this.changeRocky()
      if(this.rockId) {
        //岩画面またはルート画像画面から遷移してきた場合
        this.rockId = this.$route.query.rockId
        this.changeRock()
        this.routeId = this.$route.query.routeId
      }else{
        this.rockId = ''
      }
    }else {
      //記録作成の場合
      this.title = '記録の作成'
      this.$refs.buttonName.value = '記録する'
      this.isId = false
    }
  },
  //コンポーネント
  components: {
    Datepicker,
  }
}
</script>

<style lang="stylus" scoped >
#app
  .vdp-datepicker__calendar
    width 200px
    font-size small
    .cell
      height 25px
      line-height 25px
.levelUp
  color #d2691e
  text-decoration: underline
  font-size 30px
.levelUp-red
  color #ff0000
  font-size 20px
.record
  color #0000ff
  font-size 20px
.record-success
  font-size 20px
  text-align:center
.point1
  width 40%
  display inline-block
.point2
  width 60%
  display inline-block
img
  border-radius 50%
  height 270px
  width 270px
.progress
  background-color #C3C8D9
</style>